<script>
import { Line } from 'vue-chartjs'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  extends: Line,

  name: 'CardPrecipitationMetereology',

  data() {
    return {
      precipitation: [],
    }
  },

  props: {
    weather: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    this.weather[0].hourly.precipitation.map((element) => {
      this.precipitation.push({
        millimeters: convertToTargetUnit(element.millimeters, 'rainfall'),
        time: element.time,
      })
    })

    this.renderChart(this.computedDataSet, this.computedOptions)
  },

  watch: {
    weather() {
      this.renderChart(this.computedDataSet, this.computedOptions)
    },
  },

  computed: {
    precipitationHourXAxis: function () {
      return this.precipitation.map((parcial) => {
        return this.moment(parcial.time).format('HH:mm')
      })
    },
    precMillimetersHourYAxis: function () {
      return this.precipitation.map((parcial) => {
        return parseFloat(parcial.millimeters.replace(',', '.'))
      })
    },
    computedDataSet: function () {
      return {
        labels: this.precipitationHourXAxis,
        datasets: [
          {
            label: this.$t('Weather.precipitation'),
            borderColor: '#70CCD5',
            backgroundColor: '#70CCD5',
            fill: true,
            data: this.precMillimetersHourYAxis,
            yAxisID: 'y-axis-1',
          },
        ],
      }
    },
    computedOptions: function () {
      return {
        responsive: true,
        title: {
          display: true,
          text: '',
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              position: 'left',
              id: 'y-axis-1',
              gridLines: {
                display: false,
                beginAtZero: true,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 10,
                beginAtZero: true,
              },
            },
          ],
        },
      }
    },
  },
}
</script>
