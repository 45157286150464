export default {
  Weather: {
    precipitation: 'Rainfall (in)',
    adjust: 'Adjust',
    precipitation_hour: 'Rainfall per hour',
    wind: 'Wind',
    humidity: 'Humidity',
    moisture: 'Dew',
    adjust_precipitation: 'Adjust rainfall',
    alert:
      'We do not have the rainfall information over the last 10 days from your farm because it was added just recently. You should fill the information manually',
    adjusted_title: 'Adjusted rainfall',
    adjusted_text:
      'Thank you for your feedback, it is very important for us in order to help to improve the precision of our platform',
    close: 'Close',
  },
}
