<template>
  <div :class="classes" :text="variant === 'text'">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DfTitle',

  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'uppercase', 'gray-uppercase'].includes(value),
    },
  },

  computed: {
    classes() {
      return {
        'df-title': true,
        'df-title--primary': this.variant === 'primary',
        'df-title--secondary': this.variant === 'secondary',
        'df-title--uppercase': this.variant === 'uppercase',
        'df-title--gray-uppercase': this.variant === 'gray-uppercase',
      }
    },
  },
}
</script>

<style scoped>
.df-title--primary {
  color: #253521;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.df-title--uppercase {
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.df-title--secondary {
  color: #1a2b46;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.df-title--secondary:hover {
  color: #39af49;
}

.df-title--gray-uppercase {
  color: #aab2a9;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
</style>
