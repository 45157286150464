<template>
  <div class="text-center">
    <v-menu
      close-on-content-click
      offset-y
      left
      transition="slide-y-transition"
      min-width="400px"
      max-height="400px"
    >
      <template #activator="{ on, attrs }">
        <df-button
          class="df-button--text-secondary d-flex justify-space-between align-center"
          :flank="'fa-pull-right pl-1 fa-1x'"
          :icon="'chevron-down'"
          v-bind="attrs"
          v-on="on"
          :width-slot="widthSlot"
          plain
        >
          {{ objectActual.name }}
        </df-button>
      </template>

      <card-dropdown
        :objects="objects"
        :text-button="textButton"
        :icon-button="iconButton"
        :title="title"
        :tooltip-one="tooltipOne"
        :tooltip-two="tooltipTwo"
        :icon-one="iconOne"
        :icon-two="iconTwo"
        @clickButton="clickButton"
        @actionButton="actionButton"
      ></card-dropdown>
    </v-menu>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import CardDropdown from '../Cards/CardDropDown.vue'
export default {
  name: 'SelectorDropDown',

  components: {
    DfButton,
    CardDropdown,
  },

  props: {
    objects: {
      type: Array,
      required: true,
    },

    objectActual: {
      type: Object,
      required: true,
    },

    textButton: {
      type: String,
      default: '',
    },

    iconButton: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    iconOne: {
      type: String,
      default: '',
    },

    iconTwo: {
      type: String,
      default: '',
    },

    widthSlot: {
      type: String,
      default: '',
    },

    tooltipOne: {
      type: String,
      default: '',
    },

    tooltipTwo: {
      type: String,
      default: '',
    },
  },

  methods: {
    clickButton(object) {
      this.$emit('clickButton', object)
    },

    actionButton() {
      this.$emit('actionButton')
    },
  },
}
</script>
