export default {
  Weather: {
    precipitation: 'Precipitação mm',
    adjust: 'Ajustar',
    precipitation_hour: 'Precipitação por hora',
    wind: 'Vento',
    humidity: 'Umidade',
    moisture: 'Orvalho',
    adjust_precipitation: 'Ajustar precipitação',
    alert:
      'Sua fazenda foi criada recentemente e não temos informações de precipitação referente aos últimos 10 dias. Caso necessário, preencha manualmente.',
    adjusted_title: 'Precipitação ajustada',
    adjusted_text:
      'Agradecemos pelo seu feedback, ele é muito importante para deixarmos a ferramenta cada vez mais precisa',
    close: 'Fechar',
  },
}
