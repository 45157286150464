<template>
  <div class="card-weather">
    <div v-if="loadingWeather" class="loading__wrapper">
      <v-skeleton-loader height="154px" type="image" width="auto" />
      <hr />
      <v-skeleton-loader height="154px" type="image" width="auto" />
    </div>
    <without-weather v-else-if="!weather.length || !enabledFarm" />
    <v-card v-else>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-actions>
            <v-col cols="9" class="title-weather">
              {{ $t('weather.info') }}
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <df-button
                icon="edit"
                variant="text-secondary"
                @click="openPrecipitationModal"
              >
                {{ $t('Weather.adjust') }}
              </df-button>
            </v-col>
          </v-card-actions>
        </v-col>
      </v-row>
      <div>
        <v-row no-gutters>
          <v-col cols="12">
            <div>
              <card-weather-condition :weather="weather" />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div v-if="weather" class="grafico-precipitacao">
              <precipitation-metereology-modal
                :weather="weather"
                :key="$currentLanguage()"
              >
                <template #activator="{ on }">
                  <span
                    class="adjust-and-view-precipation d-flex justify-center mb-4 pt-2"
                    v-on="on"
                  >
                    {{ $t('Weather.precipitation_hour') }}
                    <v-icon class="icon-edit-and-clock">mdi-clock</v-icon>
                  </span>
                </template>
              </precipitation-metereology-modal>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <precipitation-modal
      v-if="isPrecipitationModalOpened"
      @close="closePrecipitationModal"
      @update-weather="updateWeather"
    />
  </div>
</template>

<script>
import CardWeatherCondition from '@/components/Weather/CardWeatherCondition.vue'
import DfButton from '@/lib/components/Button/DfButton.vue'
import PrecipitationMetereologyModal from '@/components/Weather/PrecipitationMetereologyModal.vue'
import PrecipitationModal from '@/components/Weather/PrecipitationModal.vue'
import WithoutWeather from '@/components/Banners/WithoutWeather.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CardWeather',

  components: {
    CardWeatherCondition,
    DfButton,
    PrecipitationMetereologyModal,
    PrecipitationModal,
    WithoutWeather,
  },

  data() {
    return {
      isPrecipitationModalOpened: false,
      loadingWeather: false,
      weather: [],
    }
  },

  watch: {
    currentFarmId: {
      async handler(id) {
        if (id) {
          await this.fetchPrecipitation(id)
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId', 'enabledFarm']),
  },

  methods: {
    closePrecipitationModal() {
      this.isPrecipitationModalOpened = false
    },
    async fetchPrecipitation(id) {
      this.loadingWeather = true
      try {
        const { data } = await this.$api.fazendas.precipitation(id)
        if (data) {
          this.weather = data
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingWeather = false
      }
    },
    openPrecipitationModal() {
      this.isPrecipitationModalOpened = true
    },
    async updateWeather() {
      await this.fetchPrecipitation(this.currentFarmId)
      this.closePrecipitationModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.card-weather {
  border: 1px solid #e6e9e6;
  border-radius: 4px;

  .loading__wrapper {
    padding: 12px;

    hr {
      border: 0;
      height: 1px;
      opacity: 0.2;
      border-bottom: 1px dashed #000;
      margin: 8px 0;
    }
  }
}
::v-deep .v-card {
  box-shadow: none !important;
  border-radius: 4px !important;
}
.title-weather {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #aab2a9;
}
.adjust-and-view-precipation {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1a2b46;
  cursor: pointer;
}
.icon-edit-and-clock {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 10px;
  letter-spacing: 0.127098px;
  color: #c3d1e9;
  padding-left: 7px;
}
.meteorologia .v-item--active {
  background-color: #e4e4e4;
}
.meteorologia .theme--dark.v-icon {
  color: #727272;
}
.meteorologia .v-icon.notranslate.mdi.mdi-checkbox-blank-circle.theme--dark {
  color: black;
}
.meteorologia .v-window-item {
  margin-top: -10px !important;
}
.grafico-temperatura {
  height: 320px !important;
  width: 300px !important;
  margin-left: 33px !important;
  margin-top: 63px;
}
.v-window__container {
  margin-bottom: 50px !important;
}
</style>
