<template>
  <v-item-group>
    <v-container class="mt-n4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="2">
                <v-img
                  :src="dayCondition(todaysWeather)"
                  max-width="40"
                ></v-img>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="highest-temperature">{{
                    currentTemperture
                  }}</span
                  ><span class="highest-celsius">{{
                    $unitMeasures['temperature'][$currentLanguage()]
                  }}</span>
                </div>
                <div class="mt-n2">
                  <v-icon class="icon-down">mdi-arrow-down</v-icon>
                  <span class="lowest-temperature">{{ todayTempMin }}</span
                  ><span class="lowest-celsius">{{
                    $unitMeasures['temperature'][$currentLanguage()]
                  }}</span>
                  <v-icon class="icon-up">mdi-arrow-up</v-icon>
                  <span class="lowest-temperature">{{ todayTempMax }}</span
                  ><span class="lowest-celsius">{{
                    $unitMeasures['temperature'][$currentLanguage()]
                  }}</span>
                </div>
                <div class="weather-millimeters pl-1">
                  {{ todayMmDay }}
                  {{ $unitMeasures['rainfall'][$currentLanguage()] }}
                </div>
              </v-col>

              <v-col cols="6" class="weather-forecasting-factors">
                <v-row no-gutters class="ma-0">
                  <v-col cols="12" class="d-flex justify-space-between">
                    {{ $t('Weather.wind') }}
                    <span class="climate-units"
                      >{{ windSpeed
                      }}{{ $unitMeasures['speed'][$currentLanguage()] }}</span
                    >
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-0" v-if="soilMoisture">
                  <v-col cols="12" class="d-flex justify-space-between">
                    {{ $t('Weather.humidity') }}
                    <span class="climate-units">{{ soilMoisture }}%</span>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col cols="12" class="d-flex justify-space-between">
                    {{ $t('Weather.moisture') }}
                    <span class="climate-units d-flex justify-end"
                      >{{ dewPointTemperature
                      }}{{
                        $unitMeasures['temperature'][$currentLanguage()]
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>

      <hr />

      <v-row no-gutters class="d-flex justify-center spacing-between-days">
        <v-col v-for="(day, index) in nextDaysData" :key="index">
          <v-row no-gutters class="days-of-week" justify="center">
            {{ dayWeek(day.date) }}
          </v-row>
          <div class="d-flex justify-center day-month">
            <v-row no-gutters justify="center" class="mt-n1">
              {{ dayMonth(day.date) }}
            </v-row>
          </div>
          <div class="d-flex justify-center">
            <v-row no-gutters class="image-conditions">
              <v-img :src="dayCondition(day)" />
            </v-row>
          </div>
          <v-row no-gutters justify="center">
            <v-col class="millimeters-of-rain">
              {{ dayPrecMm(day) }}
              {{ $unitMeasures['rainfall'][$currentLanguage()] }}
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col class="maximum-temperature">
              {{ dayTempMax(day)
              }}{{ $unitMeasures['temperature'][$currentLanguage()] }}
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" class="mt-n1">
            <v-col class="minimum-temperature"
              >{{ dayTempMin(day)
              }}{{ $unitMeasures['temperature'][$currentLanguage()] }}</v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'CardWeatherCondition',

  props: {
    weather: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isThereTodaysWeather() {
      return this.weather.length
    },
    todaysWeather() {
      if (this.isThereTodaysWeather) {
        return this.weather[0]
      }
      return []
    },
    nextDaysData() {
      return this.weather.slice(1)
    },
    windSpeed() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.wind_speed : 0,
        'speed'
      )
    },
    dewPointTemperature() {
      return convertToTargetUnit(
        this.isThereTodaysWeather
          ? this.todaysWeather.dew_point_temperature
          : 0,
        'temperature'
      )
    },
    soilMoisture() {
      return this.isThereTodaysWeather ? this.todaysWeather.soil_moisture : 0
    },
    todayTempMax() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.max : 0,
        'temperature'
      )
    },
    todayTempMin() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.min : 0,
        'temperature'
      )
    },
    todayMmDay() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.hourly?.amount : 0,
        'rainfall'
      )
    },
    currentTemperture() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.current : 0,
        'temperature'
      )
    },
  },

  methods: {
    dayTempMax(day) {
      return convertToTargetUnit(day.temperature.max, 'temperature')
    },
    dayTempMin(day) {
      return convertToTargetUnit(day.temperature.min, 'temperature')
    },
    dayPrecMm(day) {
      return convertToTargetUnit(day.hourly.amount, 'rainfall')
    },
    dayMonth(date) {
      return this.moment(date).format('DD')
    },
    dayWeek(date) {
      const day = this.moment(date)
        .locale(this.$root.$i18n.locale)
        .format('ddd')

      const today = this.moment().locale(this.$root.$i18n.locale).format('ddd')

      let label = this.$t('dias_semana.hoje')
      label = label[0].toUpperCase() + label.slice(1)

      return day === today ? label : day
    },
    dayCondition(item) {
      const mm = this.weather?.length ? item.hourly.amount : 0

      if (mm < 3) return 'img/sun.png'
      else if (mm > 3 && mm < 5) return 'img/cloud.png'
      else return 'img/rain.png'
    },
  },
}
</script>

<style scoped>
hr {
  border: 0;
  height: 1px;
  opacity: 0.2;
  border-bottom: 1px dashed #000;
  margin: 8px 0;
}

.highest-temperature,
.highest-celsius,
.millimeters-of-rain,
.weather-millimeters,
.climate-units {
  font-family: 'Rubik';
  font-style: normal;
}

.lowest-celsius,
.lowest-temperature,
.weather-forecasting-factors,
.days-of-week,
.minimum-temperature,
.maximum-temperature,
.day-month {
  font-family: 'Source Sans Pro';
  font-style: normal;
}
.icon-up {
  color: #f23528;
}
.icon-down,
.weather-millimeters {
  color: #4a76bc;
}

.highest-celsius,
.lowest-celsius,
.weather-forecasting-factors,
.maximum-temperature {
  color: #aab2a9;
}

.icon-up,
.icon-down {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 10px;
}
.highest-temperature {
  font-weight: 700;
  font-size: 32px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0e180c;
}
.climate-units {
  font-weight: 700;
  font-size: 32px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0e180c;
}
.highest-celsius {
  font-weight: 500;
  font-size: 19px;
  line-height: 12px;
}
.millimeters-of-rain {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #1a2b46;
}
.climate-units {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #0e180c;
}
.weather-millimeters {
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
}
.lowest-temperature {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0e180c;
}
.lowest-celsius {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding-right: 2px;
}
.weather-forecasting-factors {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}
.day-month {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #5b6459;
}
.days-of-week {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #788476;
}
.minimum-temperature {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #5b6459;
}
.maximum-temperature {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}
.spacing-between-days {
  padding: 0px 20px;
  padding-top: 8px;
}
.image-conditions {
  padding: 3px;
  max-width: 30px;
}
</style>
