<template>
  <v-alert
    class="warning-farm-disabled mb-0"
    prominent
    text-color="#4F430C"
    color="#FFF2B0"
  >
    <v-row align="center" no-gutters>
      <v-icon class="mr-2 pl-2 pr-2" color="#A68B08">mdi-alert</v-icon>
      <v-col class="grow">
        {{ $t('message_farm_disabled') }}
      </v-col>
      <v-col class="shrink">
        <v-btn small class="text-none text-button-action" to="/farms">
          {{ $t('fazenda.manager_farms') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'MessageFarmDisabled',
}
</script>

<style lang="scss" scoped>
.warning-farm-disabled {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4f430c;
  border-radius: 4px !important;
  border: 1px solid !important;
  border-color: #ede09d !important;

  .text-button-action {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    background-color: #39af49 !important;
    color: #fff !important;
  }
}
</style>
