export default {
  Weather: {
    precipitation: 'Precipitación mm',
    adjust: 'Ajustar',
    precipitation_hour: 'Precipitación por hora',
    wind: 'Viento',
    humidity: 'Humedad',
    moisture: 'Rocío',
    adjust_precipitation: 'Ajustar la precipitación',
    alert:
      'Su hacienda se creó recientemente y no tenemos información de precipitaciones de los últimos 10 días. Si es necesario, complete manualmente',
    adjusted_title: 'Precipitación ajustada',
    adjusted_text:
      'Gracias por sus comentarios, es muy importante para nosotros hacer que la herramienta sea cada vez más precisa.',
    close: 'Cerrar',
  },
}
